<template>
  <el-card>
    <el-row justify="center">
      <el-col :xs="24" :sm="24" :md="24" :lg="24">
        <!-- <el-button type="primary" @click="printDiv('printableArea')" class="my-10 text-right"><font-awesome-icon icon="fa-solid fa-print" /></el-button> -->
        <el-button type="primary" @click="print()" class="my-10 text-right">
          <font-awesome-icon icon="fa-solid fa-print"/>
        </el-button>
        <div style="position:relative;" id="printArea">
          <!-- Invoice Patient's copy  -->
          <table id="invoice-head" style="position: relative;">
            <thead>
              <tr>
                <td>
                  <img style="max-width: 100%; width: 100%;" :src="require('@/assets/invoice_new_head.png')" alt="Head" class="header-img">
                </td>
              </tr>
            </thead>
          </table>
          <div class="wrapper" style="width: 100%; float: left; background-color: #fff">
            <!-- Invoice To   -->
            <table id="invoice-to" style="margin: 0 45px 0;width: calc(100% - 90px);">
              <thead>
              <tr v-if="invoice_details.patient">
                <td style="width: 55%">
                  <h3 style="color: #000;font-weight: normal;font-size: 14px;margin: 0; margin-bottom: 5px;">
                    {{ invoice_details.patient.fullname }}</h3>
                  <p style="margin: 0;color: #000;font-size: 14px;">
                    {{ invoice_details.patient.phone_number }}
                  </p>
                </td>
                <td style="width: 45%; vertical-align: top;">
                  <table style="margin-top: 0; margin-bottom: 10px;">
                    <tr>
                    <td style="width: 40%; padding-right: 10px; text-align: right;">
                      <span style="color: #000;font-size: 14px;padding: 3px 4px; display: inline-block; margin-bottom: 5px;">
                          Invoice No :
                      </span>
                    </td>
                    <td style="width: 60%">
                      <span style="width: 100%;display: block;padding: 3px 0;margin-bottom:5px;font-size: 14px; border: 1px solid #b9b9b9;">
                        &nbsp; {{ invoice_details.uid }}
                      </span>
                    </td>
                    </tr>
                    <tr>
                      <td style="width: 40%; padding-right: 10px; text-align: right;">
                        <span style="color: #000;font-size: 14px;padding: 3px 4px;">Date :</span>
                      </td>
                      <td style="width: 60%">
                        <span style="width: 100%;display: block;padding: 3px 0;font-size: 14px; border: 1px solid #b9b9b9; margin-bottom:0;">
                          &nbsp;  {{ invoice_details.invoice_date }}
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              </thead>
            </table>
            <!-- Invoice To End  -->

            <table id="items-tbl" style="margin: 0 45px 20px;width: calc(100% - 90px);">
              <thead>
              <tr>
                <th style="width: 7%;color: #000;padding: 6px 0;">SL.</th>
                <th colspan="2" style="width: 40%;color: #000;padding: 6px 0;position: relative;">Description
                </th>
                <th :style="invoice_details.orko_therapy_package_id || invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material' ? 'width: 12%;color: #000;' : 'width: 16%;color: #000;' ">{{ invoice_details.orko_therapy_package_id?'Unit Price':'Price'}}</th>
                <th style="width: 10%;color: #000;" v-if="invoice_details.orko_therapy_package_id || invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material'">Discount</th>
                <th :style="invoice_details.orko_therapy_package_id || invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material' ? 'width: 16%;color: #000;' : 'width: 23%;color: #000;' ">Qty.</th>
                <th style="width: 15%;color: #000;">Total</th>
              </tr>
              </thead>
              <tbody>
              <tr v-if="invoice_details.product_type != 'service' && invoice_details.product_type != 'Dental' && invoice_details.product_type != 'Plaster Material'" v-for="(item, index) in invoice_details.items" :key="index">
                <td style="text-align: center;">&nbsp; {{ index + 1 }}</td>
                <td colspan="2">&nbsp;
                  {{ item.title }} 
                  <span v-if="invoice_details.service_type">
                    ({{ invoice_details.service_type }}) - 
                  </span>
                  <span v-if="invoice_details.home_service_provided_by">
                    ({{ invoice_details.home_service_provided_by }})
                  </span>

                  <ol v-if="item.therapies && item.therapies.length">
                    <li v-for="therapy in item.therapies" :key="therapy.id">{{ therapy.title }}</li>
                  </ol>
                </td>
                <td style="text-align:center" v-if="index == 0" :rowspan="invoice_details.items.length">
                  {{ invoice_details.orko_therapy_package?invoice_details.orko_therapy_package.price:item.unit_price }}
                </td>
                <td style="text-align:center" v-if="index == 0 && invoice_details.orko_therapy_package" :rowspan="invoice_details.items.length">
                  {{ invoice_details.discount_percentage }}%
                </td>
                <td style="text-align:center" v-if="index == 0" :rowspan="invoice_details.items.length">{{
                    item.quantity
                  }}
                </td>
                <td style="text-align:center" v-if="index == 0" :rowspan="invoice_details.items.length">
                    {{ invoice_details.orko_therapy_package?invoice_details.subtotal:item.total }}
                  
                </td>
              </tr>
              <tr v-if="invoice_details.product_type=='service' || invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material'" v-for="(item, index) in invoice_details.items"
                  :key="index">
                <td style="text-align: center;">&nbsp; {{ index + 1 }}</td>
                <td colspan="2">&nbsp;{{ item.title }}</td>
                <td style="text-align:center"> {{ item.unit_price }}</td>
                <td v-if="invoice_details.orko_therapy_package_id"></td>
                <td style="text-align:center" v-if="invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material'">{{ item.discount }}</td>
                <td style="text-align:center">{{ item.quantity }}</td>
                <td style="text-align:center"> {{ item.total }}</td>
              </tr>
              <tr v-if="invoice_details.items && invoice_details.items.length <5">
                <td>&nbsp;</td>
                <td colspan="2">&nbsp;</td>
                <td style="text-align:center"> &nbsp;</td>
                <td v-if="invoice_details.orko_therapy_package_id || invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material'"></td>
                <td style="text-align:center"> &nbsp;</td>
                <td style="text-align:center"> &nbsp;</td>
              </tr>
              <tr v-if="invoice_details.items && invoice_details.items.length <4">
                <td>&nbsp;</td>
                <td colspan="2">&nbsp;</td>
                <td style="text-align:center"> &nbsp;</td>
                <td v-if="invoice_details.orko_therapy_package_id || invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material'"></td>
                <td style="text-align:center"> &nbsp;</td>
                <td style="text-align:center"> &nbsp;</td>
              </tr>
              </tbody>
              <tfoot>

              <tr>
                <td colspan="2" style="color: #000;font-size: 14px;font-weight: 600; vertical-align: top; padding: 4px 0;">
                  <p style="display: inline-block; margin: 0; ">Payment Info:</p>
                </td>
                <td colspan="2"></td>
                <td v-if="invoice_details.orko_therapy_package_id || invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material'"></td>
                <!-- <td class="total-arr" style="background-color: #EFF1F0;color: #3B3E43;font-size: 20px;padding: 4px 10px; position: relative; font-weight: 600;">
                  <span style="display: inline-block; margin-top: 12px;padding-bottom: 12px;">Sub Total</span>
                </td> -->
                <td style="line-height: 1; font-weight: 600; border-bottom: 5px solid #FFF; border: 1px solid #B9B9B9; color: #3B3E43;font-size: 14px;padding: 2px 10px; position: relative; border-right: none;"
                    class="total-arr">
                    Sub Total
                </td>
                <td style="color: #3B3E43;font-size: 14px;font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ invoice_details.subtotal }}
                </td>
              </tr>

              <tr v-if="invoice_details.orko_therapy_package && invoice_details.welfare_discount">
                <td colspan="3">
                </td>
                <td></td>
                <td></td>
                <td style="line-height: 1; font-weight: 600; border-bottom: 5px solid #FFF; border: 1px solid #B9B9B9; color: #3B3E43;font-size: 14px;padding: 4px 10px; position: relative; border-right: none;"
                    class="total-arr">
                    Welfare
                </td>
                <td style="color: #000; font-size: 14px; font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ invoice_details.welfare_discount }}
                </td>
              </tr>

              <tr>
                <td colspan="3" style="color: #000;font-size: 14px;font-weight: 600; vertical-align: top; padding: 4px 0;">
                <p style="border-radius: 30px;margin-top: 0px;text-transform: uppercase; margin-bottom: 0;">{{
                      invoice_details.status == 'partial'? 'partially paid': invoice_details.status
                    }}</p>
                </td>
                <!-- <td></td> -->
                <!-- <td></td> -->
                <td></td>
                <td v-if="invoice_details.orko_therapy_package_id || invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material'"></td>
                <!-- <td class="total-arr" style="background-color: #EFF1F0;color: #3B3E43;font-size: 20px;padding: 4px 10px; position: relative; font-weight: 600;">
                  <span
                      style="display: inline-block;padding-bottom: 12px;">Discount(%){{ invoice_details.discount_reason ?  '('+invoice_details.discount_reason+')' : ''}} </span>
                </td> -->
                <td v-if="!invoice_details.orko_therapy_package" style="line-height: 1; font-weight: 600;border-bottom: 5px solid #FFF; border: 1px solid #B9B9B9;color: #3B3E43;font-size: 14px;padding: 2px 10px; position: relative; border-right: none;"
                    class="total-arr">
                    Discount(%)
                </td>
                <td v-if="!invoice_details.orko_therapy_package" style="color: #3B3E43;font-size: 14px;font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ invoice_details.discount_percentage }}
                </td>

                <td v-if="invoice_details.orko_therapy_package" style="border: 1px solid #B9B9B9;color: #000;font-size: 14px; font-weight: 600;padding: 4px 10px; position: relative; border-right: none;"
                    class="total-arr">
                  Total
                </td>
                <td v-if="invoice_details.orko_therapy_package" style="color: #000; font-size: 14px; font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ invoice_details.total }}
                </td>
              </tr>

              <tr v-if="!invoice_details.orko_therapy_package && invoice_details.welfare_discount">
                <td colspan="3">
                </td>
                <td></td>
                <td v-if="invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material'"></td>
                <td style="line-height: 1; font-weight: 600; border-bottom: 5px solid #FFF; border: 1px solid #B9B9B9; color: #3B3E43;font-size: 14px;padding: 4px 10px; position: relative; border-right: none;"
                    class="total-arr">
                    Welfare
                </td>
                <td style="color: #000; font-size: 14px; font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ invoice_details.welfare_discount }}
                </td>
              </tr>

              <tr v-if="!invoice_details.orko_therapy_package_id">
                <td colspan="2" style="color: #000;font-size: 14px;font-weight: 600; padding: 4px 0;">
                  Terms & Conditions
                </td>

                <td
                    style="text-align: center;padding-top: 1px; font-size:14px;">
                  Authorised Sign
                </td>
                <td v-if="invoice_details.orko_therapy_package_id || invoice_details.product_type=='Dental' || invoice_details.product_type=='Plaster Material'"></td>
                <td></td>
                <td v-if="invoice_details.orko_therapy_package"></td>
                <td v-if="invoice_details.orko_therapy_package"></td>
                <td v-if="!invoice_details.orko_therapy_package" style="border: 1px solid #B9B9B9;color: #000;font-size: 14px; font-weight: 600;padding: 4px 10px; position: relative; border-right: none;"
                    class="total-arr">
                  Total
                </td>
                <td v-if="!invoice_details.orko_therapy_package" style="color: #000; font-size: 14px; font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ invoice_details.total }}
                </td>
              </tr>

              <tr v-else>
                <td colspan="2" style="color: #000;font-size: 14px;font-weight: 600; padding: 4px 0;">
                  Terms & Conditions
                </td>

                <td
                    style="text-align: center;padding-top: 1px; font-size:14px;">
                  Authorised Sign
                </td>
                <td></td>
                <td></td>
                <td style="border: 1px solid #B9B9B9;color: #000;font-size: 14px; font-weight: 600;padding: 4px 10px; position: relative; border-right: none;"
                    class="total-arr">
                    Prev Paid
                </td>
                <td style="color: #000; font-size: 14px; font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ latestInstallment.id?Math.round(invoice_details.total_paid - latestInstallment.amount, 2):0 }}
                </td>
              </tr>

              <tr v-if="invoice_details.product_type == 'Dental'">
                <td colspan="2"></td>
                <td></td>
                <td></td>
                <td></td>
           
                <td style="border: 1px solid #B9B9B9;color: #000;font-size: 14px; font-weight: 600;padding: 4px 10px; position: relative; border-right: none;"
                    class="total-arr">
                  Prev Paid
                </td>
                <td style="color: #000; font-size: 14px; font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ latestInstallment.id?Math.round(invoice_details.total_paid - latestInstallment.amount, 2):0 }}
                </td>
              </tr>
              <tr v-if="invoice_details.product_type == 'Dental' || invoice_details.orko_therapy_package">
                <td colspan="2"></td>
                <td></td>
                <td></td>
                <td></td>
           
                <td style="border: 1px solid #B9B9B9;color: #000;font-size: 14px; font-weight: 600;padding: 4px 10px; position: relative; border-right: none;"
                    class="total-arr">
                  Paid Today
                </td>
                <td style="color: #000; font-size: 14px; font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ latestInstallment.id?Math.round(latestInstallment.amount, 2):0 }}
                </td>
              </tr>
              <tr v-if="invoice_details.product_type == 'Dental' || invoice_details.orko_therapy_package">
                <td colspan="2"></td>
                <td></td>
                <td></td>
                <td></td>
           
                <td style="border: 1px solid #B9B9B9;color: #000;font-size: 14px; font-weight: 600;padding: 4px 10px; position: relative; border-right: none;"
                    class="total-arr">
                  Total Paid
                </td>
                <td style="color: #000; font-size: 14px; font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ invoice_details.total_paid }}
                </td>
              </tr>
              <tr v-if="invoice_details.product_type == 'Dental' || invoice_details.orko_therapy_package">
                <td colspan="2"></td>
                <td></td>
                <td></td>
                <td></td>
           
                <td style="border: 1px solid #B9B9B9;color: #000;font-size: 14px; font-weight: 600;padding: 4px 10px; position: relative; border-right: none;"
                    class="total-arr">
                  Total Due
                </td>
                <td style="color: #000; font-size: 14px; font-weight: 600; text-align: center; border: 1px solid #B9B9B9; border-left: none;">
                  {{ invoice_details.total_due }}
                </td>
              </tr>

              </tfoot>
            </table>

            <table id="invoice-foot" style="height: 50px;">
              <thead>
              <tr>
                <td style="text-align: center">
                  <small>N.B: The Payment must not be refundable</small>
                  <!-- <img style="max-width: 100%;width: 100%;" :src="require('@/assets/invoice_footer_new.png')" alt="Footer"> -->
                </td>
              </tr>
              </thead>
            </table>
          </div>
          <div class="pagebreak"> </div>
          <!-- Installments removed as per instruction of Shamim vai -->
          <!-- <div class="pagebreak"> </div>

          <div class="wrapper" style="width: 100%; margin-top: 30px; float: left; background-color: #fff" v-if="invoice_details.installments && invoice_details.installments.length">
        

            <table id="items-tbl" style="margin: 0 45px 20px;width: calc(100% - 90px);">
              <thead>
              <tr>
                <th style="width: 20%;color: #000;padding: 6px 0;text-align: center;">Installment <br/> No.</th>
                <th style="width: 20%;color: #000;padding: 6px 0; text-align: center;">Date</th>
                <th style="width: 20%;color: #000;text-align: center;">Amount to <br/> be paid</th>
                <th style="width: 20%;color: #000;text-align: center;">Paid Amount</th>
                <th style="width: 20%;color: #000;text-align: center;">Due</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in invoice_details.installments" :key="index">
                <td style="text-align: center;">{{ index + 1 }}</td>
                <td style="text-align: center;">{{ item.date }}</td>
                <td style="text-align: center;">{{ item.amount+item.due }}</td>
                <td style="text-align: center;">{{ item.amount }}</td>
                <td style="text-align: center;">{{ item.due }}</td>
              </tr>
              </tbody>
            </table>
          </div> -->
        </div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Invoice',
  data() {
    return {
      invoice_details: {},
      printPage: false,
      latestInstallment: {}
    };
  },
  created() {
    this.getInvoiceDetails();
  },
  computed: {
    subtotal() {
      if (this.invoice_details.items) {
        return this.invoice_details.items.reduce((sum, item) => sum + item.unit_price, 0);
      }
      return 0;
    },
  },
  methods: {
    getInvoiceDetails() {
      // console.log('dd',this.$route.params.id);
      axios.get(`/api/v2/invoices/${this.$route.params.id ? this.$route.params.id : this.$route.query.id}`)
        .then((res) => {
          if (res.data.status_code == 500) {
            this.$notify({
              title: 'Failed',
              message: res.data.message,
              type: 'danger',
              duration: 2000,
            });
          }
          this.invoice_details = res.data.data;
          this.latestInstallment = {};
          if(this.invoice_details.product_type == 'Dental' || this.invoice_details.orko_therapy_package_id) {
            let lastInstallment = this.invoice_details.installments.length?this.invoice_details.installments.slice(-1):[];
            if(lastInstallment.length) {
              this.invoice_details.invoice_date = lastInstallment[0].date
              this.latestInstallment = lastInstallment[0];
            }
          }
        });
    },
    // printDiv(divName) {
    //   var printContents = document.getElementById(divName).innerHTML;
    //   var originalContents = document.body.innerHTML;

    //   document.body.innerHTML = printContents;

    //   window.print();

    //   document.body.innerHTML = originalContents;
    // },

    print() {
      this.printPage = true;
      setTimeout(() => {
        this.printSetup();
      }, 50);
    },

    printSetup() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('printArea').innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        '',
        '',
        'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0',
      );

      WinPrint.document.write(`<!DOCTYPE html>
<html>
  <head>
    <title>Invoice Detail - ${this.invoice_details.uid}</title>
    ${stylesHtml}
  </head>
  <body>
    ${prtHtml}
  </body>
</html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener('load', () => {
        setTimeout(() => {
          WinPrint.print();
          WinPrint.close();
          this.printPage = false;
        }, 200);
      });
    },
  },
};
</script>

<style scoped>
@page {
  size: A4;
  /* width: 8.5in;
  height: 5.5in; */
  margin-top: 1.5in;
  margin: 0;
}

@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }

    #invoice-foot {
      height: 0.75in; 
    }

}

table {
  font-family: "Arial", Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td, table th {
  border: none;
  padding: 0;
}

table, th, td {
  border: none;
}

#invoice-head, #invoice-foot {
  line-height: 0;
}
 #printArea .wrapper{
    /* margin-top: -20px; */
    position: relative;
    z-index: 10;
  }
#items-tbl tbody tr td, #items-tbl thead tr th {
  /* border-bottom: 1px solid #B9B9B9; */
  border: 1px solid #B9B9B9;
  font-size: 14px;
  padding: 5px 0;
}

/* #items-tbl tbody tr td:nth-child(odd) {
  background-color: #EFF1F0;
}

#items-tbl tbody tr td:nth-child(even) {
  background-color: #FDFDFD;
} */

#items-tbl tbody tr:last-child td {
  /* border-bottom: none; */
}

.total:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 15px solid transparent;
  border-left: 15px solid #000;
}

.total-arr:after {
  content: "";
    position: absolute;
    left: 82%;
    top: 2px;
    border: solid #B9B9B9;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 8px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
}

.desp-arrow:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  left: 100%;
  top: 0;
  border: 19px solid transparent;
  border-left: 35px solid #000;
}

.text-right {
  text-align: right;
}
</style>
